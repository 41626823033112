import ReactDOM from "react-dom"

const bodyPortal = (props) => {
  return ReactDOM.createPortal(
    props.children,
    document.getElementById("react-body-portal")
  )
}

export default memo(bodyPortal)
