import "./style"
import BodyPortal from "components/body-portal"
import {Modal} from "react-native"
import {shared} from "components/modal"

export default memo(shapeComponent(class ComponentsLayoutOverlay extends ShapeComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onRequestClose: PropTypes.func
  }

  setup() {
    useEventListener(window, "keyup", this.tt.onWindowKeyUp)
  }

  render() {
    const {children, className, color, dataSet: givenDataSet, onRequestClose, ...restProps} = this.props
    const component = classNames("layout--overlay", givenDataSet?.component)
    const dataSet = Object.assign({}, givenDataSet, {class: className, component})

    // Only way to get the overlay on top of a modal
    if (shared.modalsShown > 0) {
      return this.renderWithReactNativeModal({dataSet, restProps})
    }

    // Only way to get the menu button on top of an overlay (like the Unicorn blocker)
    return (
      <BodyPortal>
        {this.modalContent({dataSet, restProps})}
      </BodyPortal>
    )
  }

  renderWithReactNativeModal({dataSet, restProps}) {
    return (
      <Modal onBackdropPress={this.props.onRequestClose} onRequestClose={this.props.onRequestClose} transparent>
        {this.modalContent({dataSet, restProps})}
      </Modal>
    )
  }

  modalContent({dataSet, restProps}) {
    const {children, color, onRequestClose} = this.props
    let background

    if (color == "light") {
      background = "rgba(255, 255, 255, .85)"
    } else {
      background = "rgba(0, 0, 0, .85)"
    }

    return (
      <View style={{position: "fixed", zIndex: 997, width: "100vw", height: "100vh", background, color: "#fff"}}>
        <View dataSet={dataSet} style={{width: "100%", height: "100%"}} {...restProps}>
          {onRequestClose &&
            <a className="close-overlay-button" href="#" onClick={this.tt.onCloseOverlayButtonClicked}>
              <i className="fa fa-remove" />
            </a>
          }
          <View style={{alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
            {children}
          </View>
        </View>
      </View>
    )
  }

  onCloseOverlayButtonClicked = (e) => {
    e.preventDefault()
    this.p.onRequestClose()
  }

  onWindowKeyUp = (e) => {
    const {onRequestClose} = this.props
    const {keyCode} = digs(e, "keyCode")

    if (keyCode == 27 && onRequestClose) { // Escape key
      e.preventDefault()
      onRequestClose()
    }
  }
}))
